.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.spectext {
  animation: color-change 4s infinite;
}

@keyframes color-change {
  0% {
    color: #9cb4cc;
  }
  25% {
    color: #a8a4ce;
  }
  50% {
    color: #eb4747;
  }
  75% {
    color: #495c83;
  }
  100% {
    color: #9cb4cc;
  }
}
.homepage p {
  font-size: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 780px) {
  .homepage img {
    width: 200px;
    border-radius: 50%;
  }
}
