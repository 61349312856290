.techpage {
  /* background-color: #daeaf1; */
  @media (prefers-color-scheme: dark) {
    /* background-color: rgb(15 23 42); */
  }
}
.techpage i {
  font-size: 50px;
  /* @media (prefers-color-scheme: dark) {
    color: white;
    background-color: black;
  } */
}
.techpage i:hover {
  /* color: white;
  background-color: black;
  @media (prefers-color-scheme: dark) {
    color: black;
    background-color: white;
  } */
}
.techtitle {
  font-size: xx-large;
}
.techpage .col {
  transition: all 0.2s ease-in-out;
}
.techpage .col:hover {
  transform: scale(1.1);
}
