@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body[data-theme="dark"] {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

body[data-theme="light"] {
  background-color: var(--background-light);
  color: var(--text-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add these classes for theme support */
:root {
  --background-light: #ffffff;
  --text-light: #1a202c;
  --background-dark: #1a202c;
  --text-dark: #ffffff;
}
